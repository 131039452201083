@import "../css/variables";

.component {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--footer-height);
    min-height: var(--footer-height);

    background-color: $color-main;
    color: $color-light;
    border-top: solid 2px $color-light;
    mix-blend-mode: hard-light;

    :global {
        .wrapper {
            width: var(--body-width);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .note {
            font-weight: 300;
            font-size: 16px;
            display: none;

            @media (min-width: $screen-l) {
                display: block;
            }

            a {
                color: $color-white;
                text-decoration: none;
                text-transform: uppercase;

                &:hover {
                    color: $color-light;
                }
            }
        }

        .main-sponsors {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-gap: $size-1;
            grid-auto-rows: 20px;
            
            @media (min-width: $screen-m) {
                grid-auto-rows: 30px;
            }

            > div {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
    }
}