@import "../css/variables";

.component {
    opacity: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    width: 100%;
    height: auto;
    min-height: 200px;
    position: fixed;
    z-index: 1001;
    top: 50%;
    left: 0;
    background-color: rgba(255, 255, 255, .98);
    border-radius: 0;
    transform: translateY(-200%);
    box-shadow: 0 0 20px 5px $color-sky;
    border: solid 1px #000;
    border-left: none;
    border-right: none;

    :global {

        .notification__type {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 5px;

            &--error {
                background-color: red;
            }

            &--success {
                background-color: green;
            }
        }

        button {
            display: block;
            margin: 0 auto;
            padding: 10px 20px;
            border: solid 2px $color-white;
            background-color: $color-light;
            border-radius: $size-1;
            cursor: pointer;
            transition: background-color .2s ease-in, border-color .1s ease-in;

            &:hover,
            &:focus {
                border-color: $color-sky;
            }

            &:active {
                background-color: $color-sky;
            }
        }
    }
}