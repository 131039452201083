$color-main: #333 !default;
$color-background: #004e9e !default;
$color-dark: #000 !default;
$color-white: #fff !default;
$color-light: #ddd !default;
$color-sky: #ccc !default;
$color-accent: $color-background !default;

$size-1: 20px !default;

$screen-s: 576px !default;
$screen-m: 768px !default;
$screen-l: 992px !default;
$screen-xl: 1200px !default;
$screen-xxl: 1600px !default;

$body-width-s: 550px !default;
$body-width-m: 700px !default;
$body-width-l: 900px !default;
$body-width-xl: 1100px !default;
