@import "../css/variables";

.component {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--header-height);
    min-height: var(--header-height);
    background-color: transparent;

    @media (min-width: $screen-m) {
        border-bottom: solid 1px #333;
        background: linear-gradient(0deg, #fff calc(var(--header-height) - 86px), transparent 0px);
    }

    :global {
        canvas {
            position: absolute;
            z-index: -1;
        }

        .wrapper {
            display: flex;
            flex-flow: column;
            justify-content: space-evenly;
            
            width: var(--body-width);
            height: 100%;
            position: relative;

            @media (min-width: $screen-m) {
                justify-content: space-between;
                margin-left: -14px;
            }
        }

        .logo {
            margin-left: -5px;
            margin-top: 5px;
            margin-right: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 12px;
            height: 80%;

            .logo-pwe {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                @media (min-width: $screen-s) {
                    height: 45px;
                }

                @media (min-width: $screen-m) {
                    height: 86px;
                }
            }

            .logo-anniversary {
                height: 100%;
            }

            @media (min-width: $screen-s) {
                margin-left: -30px;
            }

            @media (min-width: $screen-m) {
                margin-left: -77px;
                margin-top: 0;
                margin-right: 0;
                align-self: center;
                justify-content: space-between;
                height: calc(100% - 33px);
                width: calc(100% + 77px);
            }
        }

        .navigation {
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
            gap: 40px;
            position: fixed;
            z-index: 1;
            padding: $size-1;
            background-color: rgba(51, 51, 51, .9);
            top: -150%;
            left: 0;
            right: 0;
            bottom: auto;
            height: 100%;
            transition: all .3s ease-in;

            .open-navigation {
                position: fixed;
                z-index: 1;
                top: $size-1;
                right: $size-1 * 2;
    
                span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                }
    
                span,
                span:before,
                span:after {
                    cursor: pointer;
                    border-radius: 1px;
                    height: 5px;
                    width: 35px;
                    background: white;
                    position: absolute;
                    display: block;
                    content: '';
                    transition-duration: .25s;
                }
    
                span:before {
                    top: -10px;
                }
    
                span:after {
                    bottom: -10px;
                }
            }

            &.open {
                top: 0;
                bottom: auto;

                .open-navigation {
                    span {
                        transform: rotate(45deg);

                        &:before {
                            top: 0; 
                            transform: rotate(0deg);
                        }

                        &:after {
                            top: 0;
                            transform: rotate(90deg);
                        }
                    }
                }
            }

            @media (min-width: $screen-m) {
                display: flex;
                justify-content: space-evenly;
                flex-flow: row;
                gap: $size-1/2;
                position: static;
                padding: 0;
                background-color: transparent;
                height: auto;

                &.open {
                    top: auto;
                }

                .open-navigation {
                    display: none;
                }
            }

            a {
                text-decoration: none;
                background-color: rgba(255, 255, 255, .95);
                background-blend-mode: hard-light;
                color: $color-dark;
                padding: 20px;
                border-radius: 5px;
                box-shadow: 0 -5px 5px 0 rgba(255,255,255,.25);
                font-size: 24px;
                white-space: nowrap;

                transition: all .2s ease-in;

                &.active {
                    box-shadow: 0 -5px 5px 0 $color-background;
                }

                &.active,
                &:hover {
                    color: $color-accent;
                    background-color: rgba(255, 255, 255, .95);
                }

                @media (min-width: $screen-m) {
                    padding: 5px 10px;
                    border-bottom: none;
                    background-color: transparent;
                    box-shadow: none;
                    font-size: 18px;

                    &.active,
                    &:hover {
                        background-color: transparent;
                    }

                    &.active {
                        box-shadow: none;
                    }
                }

                @media (min-width: $screen-l) {
                    font-size: 20px;
                }
            }
        }
    }
}