@import "~normalize.css/normalize";
@import "variables";

:root {
    --body-width: 95%;
    --header-height: 60px;
    --footer-height: 30px;

    @media (min-width: $screen-s) {
        --body-width: #{$body-width-s};
    }

    @media (min-width: $screen-m) {
        --body-width: #{$body-width-m};
        --header-height: 120px;
        --footer-height: 50px;
    }

    @media (min-width: $screen-l) {
        --body-width: #{$body-width-l};
    }

    @media (min-width: $screen-xl) {
        --body-width: #{$body-width-xl};
    }
}

:focus-visible {
    outline: none;
}

::-webkit-scrollbar {
    width: 15px;
}

* {
    scrollbar-width: 15px;
    scrollbar-color: #ffffff75 #ffffff15;
    box-sizing: border-box;
}

::-webkit-scrollbar-track {
    background: transparent;//rgb(161, 161, 161, .5);
}

::-webkit-scrollbar-thumb {
    background-color: $color-sky;
    border-radius: 20px;
    border: 2px solid $color-light;

    &:hover {
        background-color: $color-light;
    }
}

a {
    display: inline-block;
    color: $color-dark;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 2px;
    font-weight: 400;
    letter-spacing: 1px;
    transform: scale(1);
    transition: all .2s ease-in;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    text-align: center;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0 0 10px 0;
        padding: 0;
        display: block;
        position: relative;

        &:before {
            content: " ";
            position: absolute;
            top: 8px;
            left: -10px;
            display: block;
            width: 3px;
            height: 3px;
            background-color: $color-sky;
            border-radius: 100%;
        }

        ul {
            margin-left: 40px;
            margin-top: 10px;
        }
    }
}

div.info {
    background-color: $color-sky;
    color: $color-dark;
    padding: ($size-1 / 2) $size-1;
    margin: ($size-1 / 2) (0);
    border-radius: 5px;
    font-weight: 300;
    font-size: 18px;

    a {
        color: inherit;
        text-decoration-thickness: 1px;
    }
}

body {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 20px;
    font-weight: 300;

    color: $color-main;
    background-color: $color-background;
}

#root {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.main {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    flex: 0 1 100%;
    height: 100%;

    &__content {
        margin: 0;
        padding: $size-1;
        width: var(--body-width);
        min-height: calc(100%);

        background-color: rgba(255, 255, 255, .9);
        color: $color-dark;
        position: relative;

        &__wrapper {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            overflow: scroll;
            overflow-x: hidden;
            scroll-behavior: smooth;
            //padding-bottom: 160px;

            @media (min-width: $screen-xxl) {
                padding-bottom: 0;
            }
        }

        a:hover {
            transform: scale(1.02);
        }
    }
}

.fch-teaser {
    img {
        width: calc(100% + 40px);
        margin: 0 -20px;
        display: block;

        box-shadow: 0 0 30px 0 #333;

        @media (min-width: $screen-l) {
            width: 75%;
            margin: 0 auto;
            border-radius: 5px;
        }
    }
}

.pwe-teaser {
    display: flex;
    gap: 30px;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    @media (min-width: $screen-l) {
        flex-flow: row;
        align-items: flex-start;

        > div {
            width: 50%;
        }
    }

    img {
        max-width: calc(100% + 30px);
        border-radius: 5px;
        margin: 0 -15px;
        width: calc(100% + 40px);

        @media (min-width: $screen-l) {
            max-width: 100%;
            margin: 0 20px 20px 0;
            box-shadow: 0 0 15px 0 #333;
        }
    }

    h4 {
        font-weight: 400;
    }

    ul + h4 {
        margin-top: 40px;
    }

    li a {
        font-weight: 100;
    }
}

.signup {
    display: flex;
    flex-flow: column;
    gap: $size-1;
    justify-content: space-between;

    &__info {
        width: 100%;

        display: block;
        padding: $size-1;
        border: solid 1px $color-sky;
        border-radius: $size-1;

        h4 {
            color: $color-accent;
            font-weight: 400;
        }
    }

    &__form {
        width: 100%;

        padding: $size-1;
        border: solid 1px $color-sky;
        border-radius: $size-1;

        transition: background-color .4s ease-in;

        &:focus-within {
            background-color: rgba(255, 255, 255, .75);
        }
    }

    @media (min-width: $screen-m) {
        flex-flow: row;
        gap: 0;

        &__info {
            width: 33%;
        }

        &__form {
            width: 65%;
        }
    }
}



@keyframes flashNotification {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
        transform: translateY(-50%);
    }

    90% {
        opacity: 1;
        transform: translateY(-50%);
    }

    99% {
        opacity: 0;
        transform: translateY(-200%);
    }

    100% {
        opacity: 0;
    }
}

@keyframes stickyNotification {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
        transform: translateY(-50%);
    }

    100% {
        opacity: 1;
        transform: translateY(-50%);
    }
}

@keyframes hideNotification {
    0% {
        opacity: 1;
        transform: translateY(-50%);
    }

    99% {
        opacity: 0;
        transform: translateY(-200%);
    }

    100% {
        opacity: 0;
    }
}