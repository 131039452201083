@import "variables";

.form {
    display: block;
    width: 100%;

    :global {
        label {
            display: block;
            margin-top: $size-1;

            >span {
                display: block;
                font-size: 16px;
                font-weight: 400;
            }
        }

        input[type='text'],
        select {
            box-sizing: border-box;
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            border: solid 2px $color-light;
            background-color: rgba(255, 255, 255, .5);
            transition: background-color .2s ease-in, border-color .1s ease-in;

            &::placeholder {
                font-weight: 300;
                color: $color-dark;
            }

            &:not([disabled]) {

                &:focus,
                &:hover {
                    border-color: $color-sky;
                }

                &:focus {
                    background-color: rgba(255, 255, 255, 1);
                }
            }

            &:not(select):not(:placeholder-shown),
            &.not-empty {
                background-color: rgba(255, 255, 255, 1);
            }

            &[disabled] {
                cursor: not-allowed;
            }
        }

        button.submit {
            display: block;
            margin: $size-1 0 0 auto;
            padding: 10px 20px;
            border: solid 2px $color-white;
            color: #fff;
            background-color: $color-background;
            border-radius: $size-1;
            cursor: pointer;
            transition: background-color .2s ease-in, border-color .1s ease-in, opacity .3s ease-in;

            &:not([disabled]) {

                &:hover,
                &:focus {
                    border-color: $color-sky;
                }

                &:active {
                    background-color: $color-sky;
                }
            }

            &[disabled] {
                border-color: transparent;
                cursor: not-allowed;
                opacity: .5;
            }
        }

        .radio {
            &__group {

                label {
                    display: flex;
                    flex-flow: row-reverse;
                    gap: 10px;
                    justify-content: flex-end;
                    cursor: pointer;

                    &.disabled {
                        cursor: not-allowed;
                        opacity: .65;
                    }

                    i {
                        font-weight: 100;
                    }
                }

                $buttonSize: 16px;

                input[type='radio'] {
                    position: relative;
                    width: 0;
                    height: 20;
                    margin-right: $buttonSize;

                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: $buttonSize;
                        height: $buttonSize;
                    }

                    &:before {
                        border: solid 1px $color-dark;
                        border-radius: 100%;

                        transition: all .2s ease-in;
                    }

                    &:after {
                        content: "X";
                        color: $color-dark;
                        font-weight: 100;
                        margin-left: 0px;
                        margin-top: -7px;
                        font-size: 28px;
                        font-style: italic;
                        transform: scale(0);
                        transform-origin: center;

                        transition: transform .2s ease-in, opacity .2s ease-in;
                    }

                    &:checked {
                        &:before {
                            border-color: $color-sky;
                        }

                        &:after {
                            transform: scale(1);
                        }
                    }
                }

                label:not(.disabled):hover input[type='radio'] {
                    &:before {
                        border-color: $color-dark;
                    }

                    &:after {
                        opacity: .5;
                    }

                    &:not(:checked):after {
                        transform: scale(.5);
                        margin-top: -3px;
                    }
                }
            }
        }

        option:first-of-type {
            color: $color-dark;
        }
    }
}